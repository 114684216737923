import React, { useState } from "react";
import Button from "../components/button";
import Modal from "../components/modal";
import { FcGoogle } from "react-icons/fc";
import useAuth from "../hooks/useAuth";
import Spinner from "../components/spinner";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LandingPage: React.FC = () => {
  const [isSignUpModalOpen, setSignUpModalOpen] = useState(false);
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);

  // Sign up and login logic from useAuth hook
  const { loading, error, signup, login, signInWithGoogle } = useAuth();
  // Form state for both signup and login
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  // Handles changes in form fields
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  // Handles form submission for signup
  const handleSignupSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await signup(formData);
  };

  // Handles form submission for login
  const handleLoginSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await login(formData);
  };

  return (
    <>
      <div className="flex flex-col w-screen h-screen justify-center items-center landing-page">
        <div className="flex flex-row w-full">
          
          {/* Left column */}
          <div className="hidden md:flex flex-col container w-1/2 justify-center items-center">
            <img src="/fastsite-logo.svg" alt="logo" />
            <p className="py-4 font-roboto font-bold">
              Free to use, free to share, free to create.
            </p>
            <p className="py-4 font-roboto font-normal text-center">
              Spin up a webpage really fast, and then share it even faster.
            </p>
          </div>

          {/* Right column */}
          <div className="flex flex-col container w-full md:w-1/2 justify-center items-center">
            <p className="text-6xl font-roboto font-normal italic">Sign In</p>
            {/* Button div */}
            <div className="flex flex-col gap-4 my-6">
              <form
                action={`${process.env.REACT_APP_API_BASE_URL}/googleAuth/redirect`}
              >
                <Button
                  onClick={signInWithGoogle}
                  text="Sign in with Google"
                  Icon={FcGoogle}
                />
              </form>
            </div>
            <p className="text-xs">or</p> <br />
            <p
              onClick={() => setLoginModalOpen(true)}
              className="text-xs capitalize text-linkBlue cursor-pointer"
            >
              Sign In with your email address
            </p>
            <p className="text-lg italic text-customBlack capitalize my-4">
              Don't Have an Account
            </p>
            <p
              className="text-xs font-normal capitalize text-linkBlue cursor-pointer"
              onClick={() => setSignUpModalOpen(true)}
            >
              Sign Up
            </p>
          </div>
        
        </div>
      </div>

      {/* Sign Up Modal */}
      <Modal
        modalTitle="Sign Up For Fast Site"
        isOpen={isSignUpModalOpen}
        onClose={() => setSignUpModalOpen(false)}
        buttonText="Sign Up"
      >
        <div className="flex flex-col gap-3">
          <form
            className="border border-borderBlue rounded-lg p-4"
            onSubmit={handleSignupSubmit}
          >
            <div className="mb-4 flex justify-between items-center">
              <label htmlFor="name" className="w-2/6 text-left text-sm mr-4">
                Name:
              </label>
              <input
                type="text"
                id="name"
                value={formData.name}
                onChange={handleChange}
                className="w-4/5 p-1 border border-borderBlue rounded-lg"
              />
            </div>
            <div className="mb-4 flex justify-between items-center">
              <label htmlFor="email" className="w-2/6 text-left text-sm mr-4">
                Email:
              </label>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                className="w-4/5 p-1 border border-borderBlue rounded-lg"
              />
            </div>
            <div className="mb-4 flex justify-between items-center">
              <label
                htmlFor="password"
                className="w-2/6 text-left text-sm mr-4"
              >
                Password:
              </label>
              <input
                type="password"
                id="password"
                value={formData.password}
                onChange={handleChange}
                className="w-4/5 p-1 border border-borderBlue rounded-lg"
              />
            </div>
            <div className="mb-4 flex justify-between items-center">
              <label
                htmlFor="confirmPassword"
                className="w-2/6 text-left text-sm mr-4"
              >
                Re-enter Password:
              </label>
              <input
                type="password"
                id="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                className="w-4/5 p-1 border border-borderBlue rounded-lg"
              />
            </div>

            <div className="mt-4 flex justify-end">
              <button
                type="submit"
                disabled={loading}
                className={` p-2 rounded-lg text-white ${
                  loading
                    ? "bg-gray-400"
                    : "  px-4 py-2 text-white rounded bg-btnGray hover:bg-linkBlue"
                } transition-colors duration-300`}
              >
                {loading ? <Spinner /> : "Sign Up"}
              </button>
            </div>

            {error && <p className="text-red-500 mt-2">{error}</p>}
          </form>
        </div>
      </Modal>

      {/* Login Modal */}
      <Modal
        modalTitle="Log In To Fast Site"
        isOpen={isLoginModalOpen}
        onClose={() => setLoginModalOpen(false)}
        buttonText="Login"
      >
        <div className="flex flex-col gap-3">
          <form
            className="border border-borderBlue rounded-lg p-4"
            onSubmit={handleLoginSubmit}
          >
            <div className="mb-4 flex justify-between items-center">
              <label htmlFor="email" className="w-2/6 text-left text-sm mr-4">
                Email:
              </label>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                className="w-4/5 p-1 border border-borderBlue rounded-lg"
              />
            </div>
            <div className="mb-4 flex justify-between items-center">
              <label
                htmlFor="password"
                className="w-2/6 text-left text-sm mr-4"
              >
                Password:
              </label>
              <input
                type="password"
                id="password"
                value={formData.password}
                onChange={handleChange}
                className="w-4/5 p-1 border border-borderBlue rounded-lg"
              />
            </div>
            <div className="my-2 flex justify-end">
              <button
                type="submit"
                disabled={loading}
                className={`p-2 rounded-lg text-white ${
                  loading
                    ? "bg-gray-400"
                    : "px-4 py-2 text-white rounded bg-btnGray hover:bg-linkBlue"
                } transition-colors duration-300`}
              >
                {loading ? <Spinner /> : "Login"}
              </button>
            </div>
          </form>
          <a href="/forgotPassword">
            <p className="text-sm font-normal capitalize text-linkBlue cursor-pointer">
              Forgot Password<span>&#63;</span>
            </p>
          </a>
        </div>
      </Modal>
    </>
  );
};

export default LandingPage;
