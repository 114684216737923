import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  SyntheticEvent,
} from "react";
import CodeMirror from "@uiw/react-codemirror";
import { sublimeInit } from "@uiw/codemirror-theme-sublime";
import { javascript } from "@codemirror/lang-javascript";
import { html as htmlLanguage } from "@codemirror/lang-html";
import { css as cssLanguage } from "@codemirror/lang-css";
import { FaCloudUploadAlt } from "react-icons/fa";
import { IoIosLink } from "react-icons/io";
import { IoMdSettings } from "react-icons/io";
import { FaGlobe } from "react-icons/fa";
import { IoPerson, IoCopyOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import useCodeSnippet from "../hooks/useCodeSnippet";
import { toast } from "react-toastify";
import Modal from "../components/modal";
import Spinner from "../components/spinner";
import { Analytics } from "@vercel/analytics/react";

const Project: React.FC = () => {
  const navigate = useNavigate();

  const [html, setHtml] = useState("");
  const [css, setCss] = useState("");
  const [js, setJs] = useState("");
  const [publicLink, setPublicLink] = useState("");
  const [pageName, setPageName] = useState("");
  const [isLive, setIsLive] = useState(false);
  const [activeTab, setActiveTab] = useState("html");
  const [htmlLineCount, setHtmlLineCount] = useState(0);
  const [cssLineCount, setCssLineCount] = useState(0);
  const [jsLineCount, setJsLineCount] = useState(0);
  const [showPreview, setShowPreview] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const copyMessageRef = useRef<HTMLParagraphElement | null>(null);

  // state to maintain modals
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const { fetchSnippet, updateSnippet, loading } = useCodeSnippet();
  const { userId, projectId } = useParams();

  const iframeKey = useMemo(() => Math.random(), [html, css, js]);

  useEffect(() => {
    if (projectId) {
      fetchSnippet(Number(projectId))
        ?.then((res) => {
          setHtml(res?.data?.html);
          setCss(res?.data?.css);
          setJs(res?.data?.js);
          setPublicLink(res?.data?.publicLink);
          setPageName(res?.data?.pageName);
        })
        .catch((error) => {
          toast.error("Unable to fetch. Please try again later");
        });
    }
  }, [userId, projectId]);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe) {
      const document = iframe.contentDocument;
      if (document) {
        const content = `
          <style>${css}</style>
          ${html}
          <script>${js}</script>
        `;
        document.open();
        document.write(content);
        document.close();
      }
    }
  }, [html, css, js, iframeKey]);

  useEffect(() => {
    setHtmlLineCount(html?.split("\n")?.length);
  }, [html]);

  useEffect(() => {
    setCssLineCount(css?.split("\n")?.length);
  }, [css]);

  useEffect(() => {
    setJsLineCount(js?.split("\n")?.length);
  }, [js]);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (isChanged) {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isChanged]);

  const handleCodeEdit = () => {
    const codePayload = {
      id: Number(projectId),
      html: html,
      css: css,
      js: js,
      codeOutput: `
          <style>${css}</style>
          ${html}
          <script>${js}</script>
        `,
    };
    updateSnippet(codePayload).then((res) => {
      setIsChanged(false);
    });
  };

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    setShowPreview(false);
  };

  const handleTogglePageStatus = () => {
    if (projectId) {
      const payload = {
        id: Number(projectId),
        isLive: !isLive,
      };
      setIsLive((prev) => !prev);
      updateSnippet(payload);
    }
  };

  const handleSavePageName = (e: SyntheticEvent) => {
    e.preventDefault();
    if (projectId) {
      const payload = {
        id: Number(projectId),
        pageName: pageName,
      };
      updateSnippet(payload)
        .then(() => {
          toast.success("Page name saved");
        })
        .catch((err) => {
          toast.error("Page name save Failed. Please try again");
        });
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageName(e.target.value);
  };

  const togglePreview = () => {
    setShowPreview(!showPreview);
  };

  const handleChange = (value: any, type: any) => {
    if (type === "html") setHtml(value);
    if (type === "css") setCss(value);
    if (type === "js") setJs(value);
    setIsChanged(true);
  };

  const handleCopyUrl = () => {
    navigator.clipboard
      .writeText(publicLink)
      .then(() => {
        toast.success("Public link copied to clipboard");
        if (copyMessageRef.current) {
          copyMessageRef.current.classList.remove("hidden");
          copyMessageRef.current.classList.add("block");
        }
      })
      .catch((err) => {
        console.error("Failed to copy the URL to clipboard:", err);
      });
  };

  return (
    <div>
      <div className="flex flex-col md:flex-row justify-between items-center bg-gray-100 px-8 my-8">
        <div className="text-xl font-bold flex flex-row my-4">
          <img
            src="/yellow-arrow.png"
            alt="yellow-arrow"
            className=" w-9 h-7"
          />
          <p className="text-2xl md:text-3xl">Fast Site</p>
        </div>
        <button
          className={` w-fit my-4 md:w-1/6 py-4 rounded-xl text-linkBlue text-sm font-medium px-4 whitespace-nowrap focus-visible:outline-none focus-visible:ring transition-colors duration-150 ease-in-out border-r-2 border-lightBlue hover:bg-linkBlue hover:text-white ${
            showPreview
              ? "bg-activeBlack text-white"
              : "bg-lightBlue text-linkBlue"
          }`}
          onClick={togglePreview}
        >
          {showPreview ? "Hide Preview" : "Show Preview"}
        </button>

        <div className="flex rounded-xl border-0 border-lg flex-row gap-3 bg-lightBlue  md:w-1/2 my-6  border-lightBlue">
          <button
            className={`flex-1 py-4 rounded-xl text-linkBlue text-sm font-medium px-4 whitespace-nowrap focus-visible:outline-none focus-visible:ring transition-colors duration-150 ease-in-out border-r-2 border-lightBlue hover:bg-linkBlue hover:text-white ${
              activeTab === "html" && "bg-activeBlack text-white"
            }`}
            onClick={() => handleTabChange("html")}
          >
            <div className="flex flex-row justify-between items-center gap-4 md:gap-1">
              <p>HTML</p>
              <p>{htmlLineCount} Lines</p>
            </div>
          </button>
          <button
            className={`flex-1 py-4 rounded-xl text-linkBlue text-sm font-medium px-4 whitespace-nowrap focus-visible:outline-none focus-visible:ring transition-colors duration-150 ease-in-out border-r-2 border-lightBlue hover:bg-linkBlue hover:text-white ${
              activeTab === "css" && "bg-activeBlack text-white"
            }`}
            onClick={() => handleTabChange("css")}
          >
            <div className="flex flex-row justify-between items-center gap-4 md:gap-1">
              <p>CSS</p>
              <p>{cssLineCount} Lines</p>
            </div>
          </button>
          <button
            className={`flex-1 py-4 rounded-xl text-linkBlue text-sm font-medium px-4 whitespace-nowrap focus-visible:outline-none focus-visible:ring transition-colors duration-150 ease-in-out hover:bg-linkBlue hover:text-white ${
              activeTab === "js" && "bg-activeBlack text-white"
            }`}
            onClick={() => handleTabChange("js")}
          >
            <div className="flex flex-row justify-between items-center gap-4 md:gap-1">
              <p>JS</p>
              <p>{jsLineCount} Lines</p>
            </div>
          </button>
        </div>

        <div className="flex flex-row gap-4 ">
          <FaCloudUploadAlt
            onClick={handleCodeEdit}
            className="h-6 w-6 cursor-pointer text-grayDark hover:text-linkBlue"
          />
          <IoIosLink
            onClick={handleCopyUrl}
            className="h-6 w-6 cursor-pointer text-grayDark hover:text-linkBlue"
          />
          <IoMdSettings
            onClick={() => setIsSettingsModalOpen(true)}
            className="h-6 w-6 cursor-pointer text-grayDark hover:text-linkBlue"
          />
          <a href="/dashboard">
            <FaGlobe
              onClick={() => {
                navigate("/dashboard", { replace: true });
              }}
              className="h-6 w-6 cursor-pointer text-grayDark hover:text-linkBlue"
            />
          </a>
          <a href="/account">
            <IoPerson
              onClick={() => {
                navigate("/account", { replace: true });
              }}
              className="h-6 w-6 cursor-pointer text-grayDark hover:text-linkBlue"
            />
          </a>
        </div>
      </div>

      <div style={{ display: showPreview ? "none" : "block" }}>
        {activeTab === "html" && (
          <CodeMirror
            height="100vh"
            value={html}
            onChange={(value) => handleChange(value, "html")}
            extensions={[htmlLanguage()]}
            theme={sublimeInit({
              settings: {
                caret: "#c6c6c6",
                fontFamily: "monospace",
                fontSize: "15px",
              },
            })}
            basicSetup={{
              foldGutter: true,
              dropCursor: true,
              autocompletion: true,
              allowMultipleSelections: true,
              indentOnInput: true,
              foldKeymap: true,
              syntaxHighlighting: false,
              bracketMatching: true,
              highlightSelectionMatches: false,
              lintKeymap: true,
            }}
          />
        )}
        {activeTab === "css" && (
          <CodeMirror
            height="100vh"
            value={css}
            onChange={(value) => handleChange(value, "css")}
            extensions={[cssLanguage()]}
            theme={sublimeInit({
              settings: {
                caret: "#c6c6c6",
                fontFamily: "monospace",
                fontSize: "15px",
              },
            })}
            basicSetup={{
              foldGutter: true,
              dropCursor: true,
              autocompletion: true,
              allowMultipleSelections: true,
              indentOnInput: true,
              foldKeymap: true,
              syntaxHighlighting: false,
              bracketMatching: true,
              highlightSelectionMatches: false,
              lintKeymap: true,
            }}
          />
        )}
        {activeTab === "js" && (
          <CodeMirror
            height="100vh"
            value={js}
            onChange={(value) => handleChange(value, "js")}
            theme={sublimeInit({
              settings: {
                caret: "#c6c6c6",
                fontFamily: "monospace",
              },
            })}
            extensions={[javascript({ jsx: true })]}
            basicSetup={{
              foldGutter: true,
              dropCursor: true,
              autocompletion: true,
              allowMultipleSelections: true,
              indentOnInput: true,
              foldKeymap: true,
              syntaxHighlighting: false,
              bracketMatching: true,
              highlightSelectionMatches: false,
              lintKeymap: true,
            }}
          />
        )}
      </div>

      <div
        className="preview"
        style={{ display: showPreview ? "block" : "none" }}
      >
        <iframe
          title="unique"
          ref={iframeRef}
          key={iframeKey}
          style={{ width: "100%", height: "100vh" }}
        />
      </div>
      <Modal
        modalTitle="Page Settings"
        isOpen={isSettingsModalOpen}
        onClose={() => setIsSettingsModalOpen(false)}
        buttonText="Save"
      >
        <div className="flex flex-col gap-3">
          <form
            className="border border-borderBlue rounded-lg p-4 "
            onSubmit={handleSavePageName}
          >
            <div className="mb-4 flex justify-between items-center">
              <label
                htmlFor="email"
                className="w-2/6 text-left text-sm mr-4 font-roboto font-bold "
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                onChange={handleNameChange}
                value={pageName}
                className="w-full p-1 border border-borderBlue rounded-lg font-roboto "
              />
            </div>
            <div className="mb-4 flex justify-between items-center">
              <label
                htmlFor="password"
                className=" w-1/3 text-left text-sm mr-4 font-roboto font-bold "
              >
                Url
              </label>
              <p ref={copyMessageRef} className="hidden text-sm mx-3">
                Copied!
              </p>
              <p className="text-linkBlue text-xs">{publicLink}</p>
              {/* todo: add a button to copy here */}

              <IoCopyOutline
                onClick={handleCopyUrl}
                className=" text-linkBlue cursor-pointer"
                size={25}
              />
            </div>
            <hr className=" my-8" />
            <div className="flex justify-between font-roboto ">
              <p className="text-sm  text-customBlack font-semibold ">
                Un-Publish this page<span>&#63;</span>
              </p>
              <p
                onClick={handleTogglePageStatus}
                className=" text-gray-500 cursor-pointer hover:text-linkBlue"
              >
                {isLive ? "Un-Publish" : "Publish"}
              </p>
            </div>

            <div className="my-2 flex justify-end">
              <button
                type="submit"
                disabled={loading}
                className={`p-2 my-3 rounded-lg text-white ${
                  loading
                    ? "bg-gray-400"
                    : "px-4 py-2 text-white rounded bg-btnGray hover:bg-linkBlue"
                } transition-colors duration-300`}
              >
                {loading ? <Spinner /> : ""}
                save
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default Project;
